section.hero-section {
    min-height:400px;
    height: 1150px;

    .wave1 {
        z-index: 1;
        bottom: 0;
    }
    .mod {
        min-height:400px;
        height: 1150px;

        img {
            width: 100px;
            height: 100px;
        }
        .mod-wrap {
            line-height: 25px;
        }
        .btn-red {
            color: #ffffff !important;
            border-color: #F60000 !important;
            background-color: #F60000 !important;
    
            &:hover {
                border-color: #da0000 !important;
                background-color: #da0000 !important;
            }
        }
    }
    // @media screen and (max-width: 1600px) {
    //     & {
    //         height: 620px;
    //     }
    //     .mod {
    //         height: 620px;
    //     }
    // }
    // @media screen and (max-width: 1199px) {
    //     & {
    //         height: 63vw;
    //     }
    //     .mod {
    //         height: 63vw;
    //     }
    // }
}