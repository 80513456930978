
section.services-section {
    background-color: #e9e9e9;
    padding: 225px 0 90px;

    img {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .btn {
        min-width: 290px;
    }
    .h6 {
        color: #191919;
        transition: 0.3s;
    }
    a:hover .h6 {
        color: #505050;
    }
    
}